<template>
	<HokModal
		:adaptive="!!$isMobile.any"
		:width="$isMobile.any ? '95%' : '350px'"
		click-to-close
		:show-close-button="false"
		name="setupPushNotification"
		transition="scale"
		height="auto"
		v-bind="$attrs"
	>
		<div class="justify-center text-center">
			<div class="rounded-full w-28 h-28 inline-block" style="line-height: 7rem">
				<HokIcon class="m-auto inline-block" color="main" :size="12" name="icon:alarm" />
			</div>
			<h3 class="mt-4">Push-Benachrichtigungen</h3>
			<p>Nie wieder neue Jobs oder wichtige Nachrichten verpassen!</p>

			<HokButton fullwidth="always" class="mb-4" color="main" @click="$emit('result', true)">
				Aktivieren
			</HokButton>
			<HokButton color="main" fullwidth="always" is-text @click="$emit('result', false)">
				Nein Danke
			</HokButton>
		</div>
	</HokModal>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	emits: ['result']
});
</script>
